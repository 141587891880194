.project-details {
    font-family: 'Arial', sans-serif; /* Update with the exact font from the screenshot */
    color: #333; /* Assuming the text color is a standard dark grey */
    background-color: #fff; /* Assuming a white background */
    padding: 20px;
  }
  
  .project-header {
    background-color: #f4f4f4; /* Assuming a light grey background for the header */
    padding: 10px 20px;
    border-bottom: 2px solid #e0e0e0; /* Assuming there is a border under the header */
  }
  
  .project-main {
    padding: 20px;
  }
  
  .project-summary,
  .project-progress {
    margin-bottom: 20px;
  }
  
  .project-summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
    gap: 20px;
  }
  
  .project-progress {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  /* Sample styles for summary items */
  .summary-item {
    background-color: #f9f9f9; /* Assuming a very light grey background for items */
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adding a subtle shadow for depth */
  }
  
  /* Adding a style for labels within summary items */
  .summary-item label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Assuming there's a primary color for the project status and buttons */
  .primary-color {
    color: #0056b3; /* Replace with the actual primary color from the screenshot */
  }
  
  /* Assuming there's a style for the project progress indicators */
  .progress-indicator {
    display: flex;
    align-items: center;
  }
  
  /* Assuming there's a style for buttons */
  .button {
    background-color: #0056b3; /* Replace with the actual button color from the screenshot */
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #003d82; /* Darken the button color on hover */
  }
  
  /* Assuming there's a style for the progress bars */
  .progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-bar-inner {
    background-color: #4caf50; /* Replace with the actual progress bar color from the screenshot */
    height: 10px;
    width: 0%; /* You'll dynamically set this width based on progress */
  }
  
  .project-map {
    width: 100%;
    height: 400px; /* Adjust based on your needs */
    margin-bottom: 20px;
  }
  
  .map-placeholder {
    width: 100%;
    height: 100%;
    background-color: #e0e0e0; /* Placeholder color */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #333;
  }

  .back-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    margin-right: 16px; /* Adjust as needed */
  }
  
  /* Additional styles for responsiveness */
@media (max-width: 600px) {
  .project-details-container .MuiBox-root {
    flex-direction: column;
    align-items: flex-start;
  }
}